import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useRuntime } from 'vtex.render-runtime'

import Context from './utils/Context'
import { Services, Validations, Constants, NOOP } from './utils'
import UnexpectedError from './errors/UnexpectedError'
import CurrentPassword from './state-components/CurrentPassword'
import Email from './state-components/Email'
import IdentityProviders from './state-components/IdentityProviders'
import Login from './state-components/Login'
import Password from './state-components/Password'
import PhoneNumber from './state-components/PhoneNumber'
import Recaptcha from './state-components/Recaptcha'
import Token from './state-components/Token'
import UserAccountsComponent from './state-components/UserAccounts'
import useStateWithCallback from './utils/useStateWithCallback'
import useAuthStateInitializer from './utils/useAuthStateInitializer'
import {
  getUserAccountsFromCookie,
  setCookieWithUserAccounts,
} from './utils/user-accounts-cookie'

const { SessionDuration: SESSION_DURATION } = Constants

// eslint-disable-next-line camelcase
let DEPRECATED_sessionTimeout = null

const AuthState = ({
  scope,
  returnUrl,
  email: initialEmail,
  parentAppId,
  children,
  skip,
}) => {
  const initialUserAccounts = useMemo(() => getUserAccountsFromCookie(), [])
  const [email, setEmail] = useStateWithCallback(
    Validations.validateEmail(initialEmail) ? initialEmail : null
  )

  const [password, setPassword] = useState(null)
  const [recaptcha, setRecaptcha] = useStateWithCallback(null)
  const [currentPassword, setCurrentPassword] = useState(null)
  const [confirmPass, setConfirmPass] = useState(null)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [token, setToken] = useState(null)
  const [rememberMe] = useState(false)
  const [phoneNumber, setPhoneNumber] = useStateWithCallback(null)
  const [userAccounts, setUserAccounts] = useState(initialUserAccounts)
  const [meta, setMeta] = useStateWithCallback({ loading: false })
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const {
    account,
    culture: { locale },
  } = useRuntime()

  const handleConfirmPassChange = useCallback(
    pass => {
      setConfirmPass(pass)
      setPasswordsMatch(pass === password)
    },
    [setConfirmPass, setPasswordsMatch, password]
  )

  const handleTokenChange = useCallback(
    _token => {
      if (_token === '' || Validations.hasOnlyNumbers(_token)) {
        setToken(_token.substring(0, 6))
      }
    },
    [setToken]
  )

  const handleUserAccountsChange = useCallback(
    _userAccounts => {
      setCookieWithUserAccounts(_userAccounts)
      setUserAccounts(_userAccounts)
    },
    [setUserAccounts]
  )

  const toggleGlobalLoading = useCallback(
    () => setMeta(_meta => ({ ..._meta, loading: !_meta.loading })),
    [setMeta]
  )

  const setGlobalLoading = useCallback(
    (loading, callback = null) => {
      setMeta(_meta => ({ ..._meta, loading }), callback)
    },
    [setMeta]
  )

  const setMfaPhone = useCallback(
    (_phoneNumber, callback = null) => {
      setPhoneNumber(_phoneNumber, callback)
      setMeta(_meta => ({ ..._meta, loading: false }))
    },
    [setPhoneNumber, setMeta]
  )

  const {
    loading,
    error,
    value: {
      identityProviders,
      reauthenticationResult: { isUserAuthenticated, userId },
      fingerprint,
    },
  } = useAuthStateInitializer({
    autorun: !skip,
    scope,
    parentAppId,
  })

  // eslint-disable-next-line camelcase
  const DEPRECATED_withSession = useCallback(
    callback => {
      clearTimeout(DEPRECATED_sessionTimeout)
      // eslint-disable-next-line camelcase
      DEPRECATED_sessionTimeout = setTimeout(() => {
        setIsSessionExpired(true)
      }, SESSION_DURATION)

      return Services.withSession({
        accountName: account,
        returnUrl,
        scope,
        user: email,
        fingerprint,
        parentAppId,
      })(callback)
    },
    [account, email, fingerprint, parentAppId, returnUrl, scope]
  )

  return (
    <Context.Provider
      value={{
        loading,
        error,
        parentAppId,
        state: {
          scope,
          returnUrl,
          email,
          password,
          recaptcha,
          currentPassword,
          confirmPass,
          passwordsMatch,
          token,
          rememberMe,
          phoneNumber,
          userAccounts,
          meta,
          isSessionExpired,
          identityProviders,
          fingerprint,
          account,
          locale,
        },
        handlers: {
          handleEmailChange: setEmail,
          handlePasswordChange: setPassword,
          handleCurrentPasswordChange: setCurrentPassword,
          handleRecaptchaChange: setRecaptcha,
          handleConfirmPassChange,
          handleTokenChange,
          handlePhoneNumberChange: setPhoneNumber,
          handleRememberMeChange: undefined,
          handleUserAccountsChange,
          toggleGlobalLoading,
          setGlobalLoading,
          setMfaPhone,
          DEPRECATED_withSession,
          setIsSessionExpired,
        },
      }}
    >
      {typeof children === 'function'
        ? children({
            loading,
            error:
              !identityProviders && !loading ? new UnexpectedError() : error,
            isSessionExpired,
            identityProviders,
            isUserAuthenticated,
            userId,
          })
        : children}
    </Context.Provider>
  )
}

AuthState.CurrentPassword = CurrentPassword
AuthState.Email = Email
AuthState.IdentityProviders = IdentityProviders
AuthState.Login = Login
AuthState.Password = Password
AuthState.PhoneNumber = PhoneNumber
AuthState.Recaptcha = Recaptcha
AuthState.Token = Token
AuthState.UserAccounts = UserAccountsComponent

AuthState.defaultProps = {
  scope: 'STORE',
  onFailure: NOOP,
}

AuthState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.node.isRequired,
  ]).isRequired,
  returnUrl: PropTypes.string,
  email: PropTypes.string,
  scope: PropTypes.oneOf(['ADMIN', 'STORE']),
  skip: PropTypes.bool,
  onUserAuthenticated: PropTypes.func,
  parentAppId: PropTypes.string,
}

export default AuthState
