import { useRuntime } from 'vtex.render-runtime'

import { Services, NOOP } from '.'
import useAsyncCallback from './useAsyncCallback'

const useAuthStateInitializer = ({
  scope = 'STORE',
  onSuccess = NOOP,
  onFailure = NOOP,
  autorun = false,
  parentAppId = null,
} = {}) => {
  const { account } = useRuntime()

  const [, { value, error, loading }] = useAsyncCallback(
    () => {
      return Services.getInitialData({
        accountName: account,
        scopeName: scope,
        parentAppId,
      }).then(
        ({
          identityProviders: {
            oAuthProviders,
            samlProviders,
            passwordAuthentication: password,
            accessKeyAuthentication: accessKey,
            passkeyAuthentication: passKey,
            corporateProviders,
          },
          reauthenticationResult,
          fingerprint,
        }) => {
          return {
            identityProviders: {
              oAuthProviders,
              samlProviders,
              password,
              accessKey,
              passKey,
              corporateProviders,
            },
            reauthenticationResult,
            fingerprint,
          }
        }
      )
    },
    [account, parentAppId, scope],
    { onSuccess, onFailure, autorun }
  )

  return {
    value: value || {
      identityProviders: null,
      reauthenticationResult: {
        isUserAuthenticated: false,
        userId: null,
      },
    },
    error,
    loading,
  }
}

export default useAuthStateInitializer
