import errorTypeByCode from './errorTypeByCode'
import UnexpectedError from '../errors/UnexpectedError'

const getErrorCode = error => {
  // The VTEXID token api will return a status 401 and an specific text body
  // message whenever the user is blocked because of too many attempts.
  const legacyBlockedUserResponse = 'Seu login está bloqueado temporariamente.'

  if (!error) {
    return undefined
  }

  if (error.code) {
    return error.code
  }

  if (error.response && error.response.data === legacyBlockedUserResponse) {
    return 'BlockedUser'
  }

  if (error.authStatus) {
    return error.authStatus
  }

  if (error.response && error.response.data && error.response.data.authStatus) {
    return error.response.data.authStatus
  }

  if (error.message && typeof error.message === 'string') {
    return error.message.replace(/\s+/g, '')
  }

  if (error.response && error.response.statusCode === 403) {
    return 'UnauthorizedUser'
  }
}

export default function getError(error) {
  if (!error) {
    return new UnexpectedError()
  }

  const errorCode = getErrorCode(error)

  if (!errorCode || !errorTypeByCode[errorCode]) {
    return new UnexpectedError(error.details)
  }

  const ErrorType = errorTypeByCode[errorCode]

  return new ErrorType(error.details)
}
