import React from 'react'
import PropTypes from 'prop-types'

import { Validations } from '../utils'
import Context from '../utils/Context'

const VtexIdConsumer = ({ children }) => (
  <Context.Consumer>
    {({ state, handlers }) => children(state, handlers, Validations)}
  </Context.Consumer>
)

VtexIdConsumer.propTypes = {
  children: PropTypes.func.isRequired,
}

export default VtexIdConsumer
