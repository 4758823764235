const HOURS_IN_DAY = 24
const MINUTES_IN_HOUR = 60
const SECONDS_IN_MINUTE = 60

const COOKIE_NAME = '_vua'
const COOKIE_REGEX = /_vua=([^;\s]*?);/
const COOKIE_SECONDS_DURATION =
  365 * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE

const serialize = value => {
  return btoa(JSON.stringify(value))
}

const deserialize = serialized => {
  return JSON.parse(atob(serialized))
}

export const setCookieWithUserAccounts = userAccounts => {
  if (!window || !window.document || !window.location) {
    return
  }

  window.document.cookie = `${COOKIE_NAME}=${serialize(
    userAccounts
  )}; max-age=${COOKIE_SECONDS_DURATION}; path=${window.location.pathname};`
}

export const getUserAccountsFromCookie = () => {
  if (!window || !window.document) {
    return []
  }

  const cookieMatch = window.document.cookie.match(COOKIE_REGEX)

  if (cookieMatch) {
    const [, cookieValue] = cookieMatch

    return deserialize(cookieValue)
  }

  return []
}
